<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{
      labelTr
    }}</label>
    <div class="buttons has-addons" :class="sizeClass">
      <button
        class="button"
        v-for="opt in options"
        :key="opt.id"
        @click="toggle(opt.id)"
        :class="opt.class"
        :disabled="disabled"
      >
        {{ opt.label }}
      </button>
    </div>
  </div>
</template>

<script>
import input from '../sqrd/mixins/input';
export default {
  name: 'InputWeekDay',
  mixins: [input],
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Array, default: () => [] },
    format: { type: String, default: 'short' },
  },
  computed: {
    options() {
      const format = this.format;
      const value = this.value ?? [];
      return [1, 2, 3, 4, 5, 6, 7].map(id => {
        const selected = value.indexOf(id) >= 0;
        return {
          id,
          label: this.$t(['weekdays', id, format].join('_')),
          class: selected ? 'is-info' : '',
        };
      });
    },
  },
  methods: {
    toggle(id) {
      const value = this.value ?? [];
      if (value.indexOf(id) >= 0) {
        const newValue = value.filter(val => val !== id);
        this.$emit('change', newValue);
      } else {
        const newValue = [...value, id];
        newValue.sort((a, b) => a - b);
        this.$emit('change', newValue);
      }
    },
  },
};
</script>
