<template>
  <main>
    <section class="section">
      <div class="columns is-centered">
        <div
          class="column is-12-mobile is-9-tablet is-6-desktop is-5-widescreen"
        >
          <h1 class="title is-4">{{ $t('start_company_title') }}</h1>
          <h1 class="subtitle is-5">{{ $t('start_company_subtitle') }}</h1>
          <div class="field mt-12">
            <sqr-input-text
              label="start_company_name"
              placeholder="start_company_name_placeholder"
              v-model="name"
              :valid="!$v.name.$invalid"
              :invalid="$v.name.$error"
            />
            <div class="help is-danger" v-if="$v.name.$error">
              {{ $t('validation_required') }}
            </div>
          </div>
          <div class="field mt-12" :class="{ invalid: $v.workModel.$error }">
            <label class="label">{{ $t('start_company_work_model') }}</label>
            <div class="control">
              <div v-for="opt in workModels" :key="opt.id" class="mb-3">
                <label class="radio">
                  <input
                    type="radio"
                    name="workModel"
                    v-model="workModel"
                    :value="opt"
                    @change="modelSet"
                  />
                  <span class="ml-2">{{ $t(opt.label) }}</span>
                </label>
              </div>
            </div>
            <div class="help is-danger" v-if="$v.workModel.$error">
              {{ $t('validation_required') }}
            </div>
          </div>
          <div class="mt-8" v-show="workModel && workModel.id === 'other'">
            <div class="field" :class="{ invalid: $v.workModel.$error }">
              <input-week-days
                label="start_company_weekdays_worked"
                v-model="weekDaysWorked"
              />
              <div class="help is-danger" v-if="$v.workModel.$error">
                {{ $t('validation_required') }}
              </div>
            </div>
            <div class="field" :class="{ invalid: $v.due.$error }">
              <input-duration-time
                label="start_company_week_due"
                placeholder="start_company_week_due_placeholder"
                v-model="due"
                guess="hh"
                @change="convSet"
              />
              <div class="help">{{ $t('start_company_week_due_help') }}</div>
              <div class="help is-danger" v-if="$v.due.$error">
                {{ $t('validation_required') }}
              </div>
            </div>
            <div class="field" :class="{ invalid: $v.conv.$error }">
              <input-duration-time
                label="start_company_week_conv"
                placeholder="start_company_week_conv_placeholder"
                guess="hh"
                v-model="conv"
              />
              <div
                class="help"
                v-html="$t('start_company_week_conv_help')"
              ></div>
              <div class="help is-danger" v-if="$v.conv.$error">
                {{ $t('validation_required') }}
              </div>
            </div>
          </div>
          <div class="field mt-12" :class="{ invalid: $v.vacations.$error }">
            <label class="label">{{ $t('start_company_vacations') }}</label>
            <sqr-input-number
              style="width: 6.5rem"
              placeholder="start_company_vacations_placeholder"
              v-model="vacations"
              :valid="!$v.vacations.$invalid"
              :invalid="$v.vacations.$error"
            />
            <div class="help">{{ $t('start_company_vacations_help') }}</div>
            <div class="help is-danger" v-if="$v.vacations.$error">
              {{ $t('validation_required') }}
            </div>
          </div>
          <sqr-error-note :error="saveError" />
          <div class="buttons is-right">
            <sqr-button
              label="buttons_continue"
              color="primary"
              size="medium"
              @click="saveAndGo()"
              :is-loading="saving"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
label.radio {
  font-variant-numeric: tabular-nums;
}
.invalid {
  @apply border border-red-500 rounded bg-red-100 -mx-2 p-2;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';

import { Duration } from 'luxon';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import SqrInputText from '@/sqrd/SqrInputText';
import SqrInputNumber from '@/sqrd/SqrInputNumber';
import SqrButton from '@/sqrd/SqrButton';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import InputWeekDays from '@/components/InputWeekDays';
import InputDurationTime from '@/components/InputDurationTime';

export default {
  name: 'StartCompany',
  mixins: [validationMixin],
  components: {
    InputWeekDays,
    SqrInputText,
    SqrInputNumber,
    InputDurationTime,
    SqrButton,
    SqrErrorNote,
  },
  computed: {
    workModels() {
      const weekDaysWorked = [1, 2, 3, 4, 5];
      const models = [
        { id: '42', weekDaysWorked, due: 'PT42H', conv: 'PT8H24M' },
        { id: '41', weekDaysWorked, due: 'PT41H', conv: 'PT8H12M' },
        { id: '40', weekDaysWorked, due: 'PT40H', conv: 'PT8H' },
        { id: 'other', weekDaysWorked, due: '', conv: '' },
      ];
      return models.map(model => ({
        ...model,
        label: 'start_company_work_model_' + model.id,
      }));
    },
    ...mapState('locale', ['locale']),
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['uid']),
  },
  data() {
    return {
      name: '',
      workModel: undefined,
      weekDaysWorked: [1, 2, 3, 4, 5],
      due: undefined,
      conv: undefined,
      vacations: undefined,
      saving: false,
      saveError: null,
    };
  },
  validations: {
    name: { required },
    workModel: { required },
    due: { required },
    conv: { required },
    vacations: { required },
  },
  mounted() {
    this.name = this.$route.query.name ?? '';
  },
  methods: {
    modelSet() {
      const model = this.workModel;
      this.weekDaysWorked = model.weekDaysWorked;
      this.weekDaysWorked = model.weekDaysWorked;
      this.$set(this, 'weekDaysWorked', model.weekDaysWorked);
      this.$set(this, 'due', model.due);
      this.$set(this, 'conv', model.conv);
    },
    convSet(value) {
      if (!this.conv || this.conv === 'PT0S') {
        const duration = Duration.fromISO(value);
        const days = this.weekDaysWorked?.length ?? 5;
        if (duration.isValid) {
          const minutes = duration.as('minutes');
          const minutesPerDay = Math.round(minutes / days);
          this.conv = Duration.fromObject({ minutes: minutesPerDay }).toISO();
        }
      }
    },
    async saveAndGo() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        await this.save();
      }
    },
    async save() {
      try {
        this.saving = true;
        this.saveError = null;
        const locale = this.locale;

        const due = this.due;
        const max = 'PT45H';
        const conv = this.conv;
        const vacations = this.vacations;

        const weekSettings = { due, max, conv };
        const yearSettings = { vacations };

        const data = {
          cid: this.$route.query.cid,
          name: this.name,
          locale,
          weekSettings,
          yearSettings,
        };
        const res = await this.$f('companyCreate')(data);
        const { cid, aid, sid, token } = res.data;

        await this.$fb().auth().signInWithCustomToken(token);
        this.$router.replace({ name: 'sheet', params: { cid, aid, sid } });
      } catch (error) {
        this.saveError = error;
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
