<template>
  <main class="">
    <!-- TODO provide a progress indicator when available on tailwind-ui -->
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="max-w-sm mx-auto">
        <sqr-page-header class="py-8" title="start_profile_title" />
        <div v-if="user">
          <div class="columns">
            <div class="column">
              <div class="field">
                <sqr-input-text
                  label="account_given_name"
                  :value="user.givenName"
                  @change="fieldSet({ field: 'givenName', value: $event })"
                  :valid="!$v.user.givenName.$invalid"
                  :invalid="$v.user.givenName.$error"
                />
                <div class="help is-danger" v-if="$v.user.givenName.$error">
                  {{ $t('validation_required') }}
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <sqr-input-text
                  label="account_family_name"
                  :value="user.familyName"
                  @change="fieldSet({ field: 'familyName', value: $event })"
                  :valid="!$v.user.familyName.$invalid"
                  :invalid="$v.user.familyName.$error"
                />
                <div class="help is-danger" v-if="$v.user.familyName.$error">
                  {{ $t('validation_required') }}
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <sqr-input-text label="company_name" v-model="companyName" />
            <div class="help">{{ $t('validation_optional') }}</div>
          </div>
          <sqr-error-note class="field" :error="saveError" />
          <div class="pt-4 buttons is-right">
            <sqr-button
              label="buttons_continue"
              color="primary"
              @click="next()"
              :is-loading="saving"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import SqrPageHeader from '@/sqrd/SqrPageHeader';
import SqrInputText from '@/sqrd/SqrInputText';
import SqrButton from '@/sqrd/SqrButton';
import SqrErrorNote from '@/sqrd/SqrErrorNote';

export default {
  name: 'StartUser',
  mixins: [validationMixin],
  components: {
    SqrPageHeader,
    SqrInputText,
    SqrButton,
    SqrErrorNote,
  },
  computed: {
    ...mapGetters('auth', ['uid']),
    ...mapState('locale', ['locale']),
    ...mapState('user', { user: 'record' }),
    ...mapState('user', ['loading', 'loadError', 'saving', 'saveError']),
  },
  data() {
    return {
      companyName: '',
    };
  },
  validations: {
    user: {
      givenName: { required },
      familyName: { required },
    },
  },
  mounted() {
    this.loadOrCreate();
  },
  beforeDestroy() {
    this.unsub({});
  },
  methods: {
    ...mapActions('user', ['sub', 'unsub', 'init', 'save']),
    ...mapMutations('user', ['fieldSet']),
    async loadOrCreate() {
      try {
        const user = await this.sub({ path: ['users'], id: this.uid });
        if (!user) {
          this.init({
            id: this.uid,
            path: ['users'],
            record: {
              givenName: '',
              familyName: '',
              locale: this.locale,
            },
          });
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async next() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        await this.save({});
        this.$router.replace({
          name: 'start-company',
          query: { name: this.companyName },
        });
      }
    },
  },
};
</script>
